import { LeaderboardFilter } from '@commonstock/common/src/api/asset';
import { motion } from 'framer-motion';
import { css, cx } from '@linaria/core';
import React from 'react';
import { GroupChannel } from 'sendbird';
import { TextSubhead } from '../../composing/CSText';
import { Menu, MenuItem } from '../../components/styles';
import { Colors } from '../../theme/constants';
import { regroupChannels, useChannels } from '../chat/ChannelContext';
type DropDownProps = {
  channel?: GroupChannel;
  isOpen: boolean;
  setChannel: (channel?: GroupChannel) => void;
  setType: (type: LeaderboardFilter) => void;
  type: LeaderboardFilter;
  showChannels: boolean;
};
const variants = {
  hide: {
    transition: {
      duration: 0.1
    },
    transitionEnd: {
      display: 'none'
    },
    top: '2rem'
  },
  show: {
    display: 'block',
    top: '1.4rem'
  }
};

const LeaderboardDropDown = ({
  channel,
  isOpen,
  setChannel,
  setType,
  type,
  showChannels
}: DropDownProps) => {
  const {
    channels
  } = useChannels();
  let {
    groupChannels
  } = regroupChannels(channels);
  groupChannels = groupChannels.filter(c => JSON.parse(c.data).settings?.leaderboard_enabled);
  const isFollowing = !channel && type === LeaderboardFilter.Follow;
  const isGlobal = !channel && type === LeaderboardFilter.Global;

  const onClickGlobal = () => {
    setChannel(undefined);
    setType(LeaderboardFilter.Global);
  };

  const onClickFollowing = () => {
    setChannel(undefined);
    setType(LeaderboardFilter.Follow);
  };

  const onClickChannel = (c: GroupChannel) => {
    setChannel(c);
    setType(LeaderboardFilter.Follow);
  };

  return <motion.div animate={isOpen ? 'show' : 'hide'} initial={isOpen ? 'show' : 'hide'} className={wrapperClass} variants={variants}>
      <Menu className={menuClass}>
        <MenuItem onClick={onClickGlobal} className={cx(isGlobal && 'active')}>
          Global
        </MenuItem>
        <MenuItem onClick={onClickFollowing} className={cx(isFollowing && 'active')}>
          Following
        </MenuItem>
        {showChannels && groupChannels?.map(c => <MenuItem key={c.url} onClick={() => onClickChannel(c)} className={cx(channel?.url === c.url && 'active')}>
              <TextSubhead ellipsis>{c.name}</TextSubhead>
            </MenuItem>)}
      </Menu>
    </motion.div>;
};

export default LeaderboardDropDown;
const wrapperClass = "wu4yrlp";
const menuClass = "m17nun2y";

require("../../../.linaria-cache/packages/oxcart/src/scopes/trending/LeaderboardDropDown.linaria.module.css");